import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function CandidateList(type = null ) {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);
    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false },
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "referred_by_name", sortable: true , label: "Referred By"},
        { key: "mobile_number", sortable: true },
        { key: "source", sortable: true },
        { key: "role", sortable: true },
        { key: "status_label", sortable: true,label: 'Status' },
        { key: "actions" }
    ];

    const perPage = ref(10);
    const totalCandidateList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref(new URLSearchParams(window.location.search).get('q') ? new URLSearchParams(window.location.search).get('q') : "");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const createdByFilter = ref(null);
    const referredByFilter = ref(null);
    const recruiterByFilter = ref(null);
    const sourceByFilter = ref(null);
    const candidatesMetaData = ref([]);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalCandidateList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, statusFilter,createdByFilter,referredByFilter,recruiterByFilter,sourceByFilter], () => {
        refetchData();
    });

    const fetchCandidateList = (ctx, callback) => {
        store
            .dispatch("app-candidates/fetchCandidates", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                createdBy: createdByFilter.value,
                referredBy: referredByFilter.value,
                recruiterBy: recruiterByFilter.value,
                sourceBy: sourceByFilter.value,
                type:type
            })
            .then(response => {
                const candidates = response.data.data;
                const { total } = response.data.meta;

                callback(candidates);
                totalCandidateList.value = total;
                candidatesMetaData.value = candidates;
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching candidates",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    return {
        fetchCandidateList,
        tableColumns,
        perPage,
        currentPage,
        totalCandidateList,
        candidatesMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        statusFilter,
        createdByFilter,
        referredByFilter,
        recruiterByFilter,
        sourceByFilter
    };
}
